import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useMemo,
} from "react";

type ProviderProps = {
  children: React.ReactNode;
};

type AudioContextType = {
  stopSong(): void;
  unmute(): void;
};

const AudioProviderContext = createContext<AudioContextType | null>(null);

export const AudioProvider = (props: ProviderProps) => {
  const [muted, setIsMuted] = useState(true);
  const audioRef = useRef<HTMLAudioElement>(null);

  const stopSong = useCallback(() => {
    if (!audioRef.current) {
      return;
    }

    const isPlaying =
      audioRef.current.currentTime > 0 &&
      !audioRef.current.paused &&
      !audioRef.current.ended &&
      audioRef.current.readyState > audioRef.current.HAVE_CURRENT_DATA;

    if (isPlaying) {
      audioRef.current.pause();
    }

    setIsMuted(true);
  }, [audioRef]);

  const unmute = useCallback(() => {
    if (!audioRef.current) {
      return;
    }

    if (audioRef.current.paused) {
      audioRef.current.play();
    }

    setIsMuted(false);
  }, [setIsMuted, audioRef]);

  const contextValue: AudioContextType = useMemo(
    () => ({
      unmute,
      stopSong,
      muted,
    }),
    [muted, unmute, stopSong]
  );

  return (
    <>
      <audio id="audio" ref={audioRef} autoPlay loop muted={muted}>
        <source src={"/music/thank you.mp3"} />
        <source src="/music/thank you.m4a" />
      </audio>

      <AudioProviderContext.Provider value={contextValue}>
        {props.children}
      </AudioProviderContext.Provider>
    </>
  );
};

export function useAudioPlayback() {
  const contextValue = useContext(AudioProviderContext);
  if (!contextValue) {
    return () => {};
  }

  return contextValue;
}
